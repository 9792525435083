body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page {
  /* height: calc(100vh - 30px); */
  margin: 15px 15px 0;
  padding-bottom: 15px;
  overflow-y: auto;
}

.icon {
  margin-right: 4px;
}

.color-primary {
  color: #4393ff;
}

.color-danger {
  color:  #e9614d;
}

.color-dark {
  color: #000;
}

.color-gray {
  /* color: #9aa8bf; */
  color: rgba(0, 0, 0, .4);
}

.color-weixin {
  color: #3caf38;
}

.color-qq {
  color: #16b5fb;
}

.color-weibo {
  color: #fb9b35;
}

.color-orange {
  color: #ec8135;
}

.color-lightOrange {
  color: #ee9b57;
}

.text-sm {
  font-size: 12px;
}

.text-md {
  font-size: 14px;
}

.text-lg {
  font-size: 20px;
}

.text-xl {
  font-size: 24px;
}

.text-xxl {
  font-size: 30px;
}

.pure-btn {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 12px;
}

.pure-text {
  width: 100%;
  text-align: center;
  resize: none;
  border: none;
  background-color: transparent;
  user-select: none;
  pointer-events: none;
}

.qr-code {
  padding: 10px;
  border: 1px solid #d2dde9;
  border-radius: 12px;
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-normal {
  white-space: normal;
}