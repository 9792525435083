/* antd 样式重置 */
.am-card {
  .am-card-header {
    padding: 15px 15px;
    font-size: 14px;
  }
  .am-card-body {
    min-height: 40px;
    &.with-padding {
      padding: 15px 15px 6px 30px;
    }
  }
}

.am-modal {
  .am-modal-header {
    border-bottom: 1px solid #ddd;
  }
  .am-modal-body {
    font-size: 12px;
    .am-list {
      margin-top: -1px;
    }
  }
}

.am-list-item .am-list-line .am-list-brief {
  white-space: normal!important;
}

.am-button {
  &.am-button-ghost {
    border-color: #fb9b35;
    color: #fb9b35;

    &::before {
      width: calc(200% - 2px) !important;
    }
  }
  &.am-button-primary {
    background-color: #fb9b35;
    &.am-button-active {
      background-color: #f9e1ca;
      color: #fff;
    }
    &::before {
      border: none !important;
    }
  }
  &.round {
    border-radius: 30px;
    &.am-button-disabled {
      color: rgba(0, 0, 0, .4)
    }
    &.am-button-small {
      padding: 0 13px;
      font-size: 12px;
    }
    button {
      display: inline-block;
      padding: 8px 0;
    }
    &::before {
      border-radius: 30px !important;
    }
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html:not([data-scale]) .round.am-button-ghost::before {
    border: 1px solid #fb9b35;
  }
  html:not([data-scale]) .round.am-button-primary::before {
    border: 1px solid #fb9b35;
  }
}
